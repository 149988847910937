<template>
  <div class="fill-height">
    <Navbar />
    <!-- <CurrentBroadcasts /> -->
    <v-dialog :value="paymentError" persistent max-width="500" content-class="rd-custom-modal">
      <v-card>
        <v-card-title class="headline red--text">
          <v-layout justify-center>
            <h3 class="p-5 justify-center">
              {{ $t("payment.paymentError") }}
            </h3>
          </v-layout></v-card-title>
        <v-card-text class="vcardtext">
          <v-layout justify-center>
            <p>{{ error }}</p>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn customBtn pattern-btn text-capitalize font-16 white--text" @click="payAgain()">
            <span>{{ $t("payment.payAgain") }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main class="payment-det fill-height">
      <div class="payment-detail-row">
        <div class="payment-detail-col">
          <div class="payment-lft-col">
            <h2 v-if="isAssociate" class="green-text payment-dtl-title">{{ $t("payment.associatePaymentAlert") }}</h2>
            <h2 v-if="hasCashOrChequeMethod" class="green-text payment-dtl-title">{{ $t("payment.hasCashOrChequeMethod") }}</h2>
            <h2 v-if="user.is_new_user && !isAssociate && !hasCashOrChequeMethod" class="green-text payment-dtl-title">{{ $t("payment.payTitleNew") }}</h2>
            <h2 v-else-if="!isAssociate && !hasCashOrChequeMethod" class="green-text payment-dtl-title">{{ $t("payment.payTitle") }}</h2>
            <p v-if="!isAssociate && !hasCashOrChequeMethod">{{ $t("payment.payDescription") }}</p>
            <p class="rd-alert" v-if="paymentError">{{ apiErrorMessage }}</p>
            <p class="rd-alert" v-if="user.failed_payment_reason">{{ user.failed_payment_reason }}</p>
            <div class="w-100 rd-custom-tab-wrapper" v-if="!hasCashOrChequeMethod && !isAssociate">
              <label class="form-label">{{ $t("payment.payBy") }}</label>
              <v-tabs v-model="tab" class="rd-custom-tab">
                <v-tab :ripple="false" class="mr-2" value="one" @click="changePaymentMethod"
                  :disabled="!hasPaymentMethod && !currentPaymentMethod" key="0">
                  <span class="p-relative "><v-icon class="mr-1">mdi-credit-card</v-icon>
                    <br />
                    <label class="mt-1 mb-0">{{ $t("payment.payByCurrentPaymentMethod")
                      }} </label></span>
                </v-tab>
                <v-tab :ripple="false" class="ml-2" value="two" @click="changePaymentMethod" key="1">
                  <span class="p-relative"><v-icon class="mr-1">mdi-credit-card</v-icon>
                    <br />
                    <label class="mt-1 mb-0">{{ $t("payment.payByNewCard") }}</label></span>
                </v-tab>
              </v-tabs>
              <v-card-text class="pa-0">
                <v-window>
                  <v-window-item value="one" v-if="
                    hasPaymentMethod &&
                    currentPaymentMethod &&
                    !updatePaymentMethod
                  ">
                    <v-card-text class="pl-0 pr-0 pb-0">
                      <div class="visa-card-wrapper pattern-green pattern-green-after">
                        <div class="visa-card-wrapper-inr">
                          <div class="card-chip-wrapper">
                            <img src="./../assets/chip.png" alt="chip" />
                            <v-icon class="wifi">mdi-wifi</v-icon>
                          </div>
                          <label class="text-h6">
                            &bull; &bull; &bull; &bull; &nbsp;&nbsp; &bull; &bull; &bull; &bull; &nbsp;&nbsp; &bull;
                            &bull; &bull;
                            &bull; &nbsp;&nbsp;
                            {{ currentPaymentMethod.card.last4 }}
                          </label>
                          <div class="card-holder-wrap">
                            <h3 class="card-holder">{{ currentPaymentMethod.billing_details.name }}</h3>
                          </div>
                          <div class="expire-wrap text-right">
                            <span class="mr-2">{{ currentPaymentMethod.card.exp_month }} /
                              {{ currentPaymentMethod.card.exp_year }}</span>
                            <i class="text-3xl fab" :class="'fa-cc-' + currentPaymentMethod.card.brand"
                              style="font-size: 2rem !important;" />
                          </div>
                        </div>
                      </div>
                      <div class="text-center">
                        <v-btn class="w-100 mt-5 btn customBtn pattern-btn text-capitalize white--text font-16"
                          @click="chargeDuePayment" :disabled="paymentButton" :loading="paymentButton">
                          <span>{{ $t("payment.pay") }} ${{ amount }}</span>
                        </v-btn>
                      </div>
                    </v-card-text>
                  </v-window-item>
                  <v-window-item value="two" v-if="updatePaymentMethod">
                    <v-card-text class="pl-0 pr-0 pb-0">
                      <label class="form-label">{{ $t('Card Holder Name') }}</label>
                      <v-text-field id="card-holder-name" type="text" hide-details="auto" solo dense
                        :label="$t('Card Holder Name')" v-model="newCardHolderName" color="green darken-4"
                        class="mb-4"></v-text-field>

                      <!-- Stripe Elements Placeholder -->
                      <label class="form-label">{{ $t("payment.cardNumber") }}</label>
                      <div class="stripeElement stripeElementNew" id="card-element"></div>
                      
                      <div class="error-msg" v-if="cardError !== ''">
                        {{ cardError }}
                      </div>
                      <div class="text-center">
                        <v-btn class="w-100 mt-5 btn customBtn pattern-btn text-capitalize white--text font-16"
                          id="card-button" :loading="saving" :disabled="saving" :data-secret="setupIntent.client_secret"
                          v-if="setupIntent">
                          <span v-if="user.is_new_user">{{ $t("payment.pay") }} ${{ amount }}</span>
                          <span v-else> {{ $t("payment.pay") }} ${{ amount }}</span>
                      </v-btn>
                      </div>
                    </v-card-text>
                  </v-window-item>
                </v-window>
              </v-card-text>
            </div>
            <div class="unauthorizedBox w-100 text-center" v-else>
              <label>
                <span class="center-image"><img src="../assets/unautorized.svg" alt="" height="150px" /></span>
                <h5 class="mt-4 text-center" v-if="hasCashOrChequeMethod">
                  {{ $t("payment.hasCashOrChequeMethod") }}
                </h5>
                <h5 class="mt-4 text-center green-text payment-dtl-title" v-if="isAssociate">
                  {{ $t("payment.associatePaymentAlert") }}
                </h5>
              </label>
            </div>
          </div>
        </div>
        <div class="payment-detail-col payment-dtl-right-col">
          <div class="payment-dtl-right" v-if="!hasCashOrChequeMethod && !isAssociate">
            <div>
              <h2 class="green-text payment-dtl-title">{{ $t("payment.billingDetails") }}</h2>
              <div class="billing-info">
                <ul>
                  <li>
                    <span>{{ $t("payment.billedTo") }}:
                    </span>
                    <label>{{ user.user_detail.company_name }}</label>
                  </li>
                  <li>
                    <span>{{ $t("payment.monthlyAmount") }}:
                    </span>
                    <label>${{ amount }}</label>
                  </li>
                  <li v-if="!user.is_new_user">
                    <span>{{ $t("payment.vinHistoryAmount") }}:
                    </span>
                    <label>${{ user.vin_report_payment }}</label>
                  </li>
                  <li v-if="!user.is_new_user">
                    <span>{{ $t("payment.marketingCampaignAmount") }}:
                    </span>
                    <label>${{ user.marketing_campaign_payment }}</label>
                  </li>
                  <li v-if="!user.is_new_user">
                    <span>{{ $t("payment.dueDate") }}:
                    </span>
                    <label class="billing-due-date">{{ user.user_subscription.subscription_ends_at | MMDDYYdatefilter }}</label>
                  </li>
                </ul>
              </div>
              <div class="total-wrapper">
                <h2>
                  <span>{{ $t("payment.totalAmount") }}</span>
                  <label>${{ amount }}</label>
                </h2>
                <p>
                  <v-icon>mdi-lock</v-icon>
                  <span>{{ $t("payment.billingInfo") }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-main>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import CurrentBroadcasts from "../components/CurrentBroadcasts";
import { mapActions, mapGetters } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import {
  MONTHLY_BY_CARD,
  RECURRING_BY_CARD,
  CASH,
  CHEQUE,
} from "@/constants/common";

export default {
  components: {
    Navbar,
    Footer,
    CurrentBroadcasts,
  },

  data() {
    return {
      saving: false,
      intentToken: null,
      currentPaymentMethod: null,
      updatePaymentMethod: false,
      cardError: "",
      paymentError: false,
      error: "",
      tab: 0,
      paymentButton: false,
      newCardHolderName: ""
    };
  },

  computed: {
    ...mapGetters({
      user: "user/getUser",
      setupIntent: "payment/setupIntent",
      paymentMethod: "payment/defaultPaymentMethod",
      loader: "getLoaderStatus",
    }),
    isAssociate() {
      return !!this.user.parent_id;
    },
    hasPaymentMethod() {
      return !!this.paymentMethod;
    },
    isCard() {
      return [RECURRING_BY_CARD, MONTHLY_BY_CARD].includes(
        this.user.user_subscription.payment_method.name
      );
    },
    amount() {
      return this.user.user_subscription.amount + this.user.pending_car_history_payment;
    },
    hasCashOrChequeMethod() {
      return [CASH, CHEQUE].includes(this.user.payment_method);
    },
  },
  async mounted() {
    await this.fetchPaymentMethod();
    if(this.user.is_new_user) {
      this.tab = 1;
    }
  },
  methods: {
    ...mapActions({
      fetchSetupIntent: "payment/setupIntent",
      fetchDefaultPaymentMethod: "payment/defaultPaymentMethod",
      savePaymentMethod: "payment/addPaymentMethod",
      getProfile: "user/getProfile",
      addDuePayment: "payment/addDuePayment",
    }),
    async chargeDuePayment() {
      this.paymentButton = true;
      this.$store.commit("setLoader", true);
      try {
        await this.addDuePayment();
        await this.getProfile();
        this.$router.push({ name: "MembersDirectory" });
      } catch ({ message }) {
        this.paymentButton = false;
        this.error = message.custom[0];
        if (message.custom) {
          this.paymentError = true;
        }
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async changePaymentMethod() {
      this.updatePaymentMethod = !this.updatePaymentMethod;
      if (this.updatePaymentMethod) {
        await this.initSetupIntent();
        await this.setupCardElement();
      } else {
        await this.fetchPaymentMethod();
      }
    },
    async fetchPaymentMethod() {
      this.$store.commit("setLoader", true);
      try {
        await this.fetchDefaultPaymentMethod();
        this.currentPaymentMethod = this.paymentMethod;
        if (this.currentPaymentMethod === null) {
          await this.changePaymentMethod();
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async initSetupIntent() {
      this.$store.commit("setLoader", true);
      try {
        await this.fetchSetupIntent();
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async setupCardElement() {
      this.$store.commit("setLoader", true);
      try {
        // SETUP CARD ELEMENT
        const stripe = await loadStripe(process.env.VUE_APP_PUBLIC_KEY);

        const elements = stripe.elements({
          fonts: [
            {
              cssSrc: "https://fonts.googleapis.com/css?family=Source+Code+Pro",
            },
          ],
        });

        const elementStyles = {
          base: {
            color: "#32325D",
            fontWeight: 500,
            fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
            fontSize: "16px",
            fontSmoothing: "antialiased",

            "::placeholder": {
              color: "#7f7f8c",
            },
            ":-webkit-autofill": {
              color: "#7f7f8c",
            },
          },
          invalid: {
            color: "#E25950",

            "::placeholder": {
              color: "#7f7f8c",
            },
          },
        };

        const elementClasses = {
          focus: "focused",
          empty: "empty",
          invalid: "invalid",
        };

        const cardElement = elements.create("card", {
          hidePostalCode: true,
          style: elementStyles,
          classes: elementClasses,
        });

        cardElement.mount("#card-element");

        // VERIFY CARD DETAIL
        const cardHolderName = document.getElementById("card-holder-name");
        const cardButton = document.getElementById("card-button");
        const clientSecret = cardButton.dataset.secret;
        // const clientSecret = this.setupIntent.client_secret;
        cardButton.addEventListener("click", async () => {
          this.saving = true;
          const { setupIntent, error } = await stripe.confirmCardSetup(
            clientSecret,
            {
              payment_method: {
                card: cardElement,
                billing_details: { name: cardHolderName.value },
              },
            }
          );

          if (error) {
            this.saving = false;
            // this.cardError = error.message;
            this.error = error.message;
            this.paymentError = true;
            // Display "error.message" to the user...
            console.log(error);
          } else {
            // The card has been verified successfully...
            await this.saveCardDetails(setupIntent);
          }
        });
      } catch ({ message }) {
        console.log(message);
      } finally {
        this.saving = false;
        this.$store.commit("setLoader", false);
      }
    },

    payAgain() {
      // this.$store.commit("setLoader", true);
      // window.location.reload();
      this.paymentError = false;
    },

    async saveCardDetails(setupIntent) {
      this.$store.commit("setLoader", true);
      try {
        await this.savePaymentMethod({
          payment_method: setupIntent.payment_method,
        });
        this.updatePaymentMethod = false;
        // await this.fetchPaymentMethod();
        await this.getProfile();
        this.$router.push({ name: "MembersDirectory" });
      } catch ({ message }) {
        this.error = message.custom[0];
        if (message.custom) {
          this.paymentError = true;
        }
        console.log(message);
      } finally {
        this.saving = false;
        this.$store.commit("setLoader", false);
      }
    },
  },
};
</script>
